import React from 'react'
import PropType from "prop-types"

import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';

const ContentBox = styled.div`
  position: relative;
  margin-bottom: 100px;
  padding: 20px;
  background: rgb(199,255,208);
  background: linear-gradient(90deg, rgba(199,255,208,1) 0%, rgba(152,222,217,1) 100%);
  transform: skew(0deg, 5deg);

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    padding: 50px;
    transform: skew(10deg, 0deg);
  } 
`

const Number = styled.span `
  display: inline-block;
  position: absolute;
  top: -50px;
  left: 50px;
  font-family: ${variables.brand_font};
  font-size: 4em;
  font-weight: ${variables.font_bold};
  transform: skew(0deg, -5deg);

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
      font-size: 5em;
      transform: skew(-10deg, 0deg);
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
      font-size: 6em;
  }
`

const H3 = styled.h3 `
  position: relative;
  font-family: ${variables.brand_font};
  font-size: ${variables.font_size__mobile_small};
  font-weight: ${variables.font_bold};
  margin-bottom: 20px;
  padding: 10px 0 5px;
  transform: skew(0deg, -5deg);

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_small};
    transform: skew(-10deg, 0deg);
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_small};
  }
`

const Text = styled.p `
  font-size: ${variables.font_size__mobile_text};
  padding: 10px 0 20px;
  white-space: pre-line;
  transform: skew(0deg, -5deg);

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    padding: 20px 0;
    transform: skew(-10deg, 0deg);
  } 

  & {
    a {
      font-style: italic;
      font-weight: ${variables.font_bold};
      text-decoration: underline;
    }
  }
`

const ContentRectangle = ({ heading, text, number}) => {
  return (
    <ContentBox>
      <Number>{number}</Number>
      <H3 className="left">{heading}</H3>
      <Text>{text}</Text>
    </ContentBox>
  )
}

ContentRectangle.prototype = {
  heading: PropType.string.isRequired,
  text: PropType.string.isRequired,
  number: PropType.number,
}

ContentRectangle.defaulType = {
  heading: "",
  text: "",
  number: {},
}

export default ContentRectangle
