import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Btn from "../components/button"
import List from "../components/list"
import Section from "../components/section"
import ContactFormSection from "../components/contactFormSection"
import ContentRectangle from "../components/contentRectangle"
import ContentBlock from "../components/contentBlock"
import HeroBg from "../components/heroBg"
import bgImage from "../images/backgrounds/hero-website-seo.png"
import { StaticImage } from "gatsby-plugin-image"

const WebsiteAndSeoPage = ({ location }) => (
  
  <>
  {/* Hero section  */}
  <Layout
    breadcrumb={
      {"Website & SEO": "/website-und-seo"}
    }
  >
    <Seo 
      title="Mit Website-Optimierung (SEO) & Webdesign zum Erfolg | cliqit"
      description="Eine gute Website soll nicht nur ansprechend aussehen und den Nutzer leiten, sondern benötigt zusätzlich die richtige Struktur und technischen Optimierungen (SEO), um in Suchmaschinen gefunden werden zu können."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section noSeparator>
      <div className="row">
        <div className="col-md-8">
          <h1 >Website Erstellung & SEO</h1>
          <p className="subheading align-left">Eine gute Website soll nicht nur ansprechend aussehen und den Nutzer leiten, sondern benötigt zusätzlich die richtige Struktur und technischen Optimierungen (SEO), um in Suchmaschinen gefunden werden zu können.</p>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <List 
            columnCount={2}
            listItems={[
              { text:"Suchmaschinenoptimierung (SEO)", href:"#seo-teil-1", key:"1" },
              { text:"Webdesign & Struktur", href:"#webdesign", key:"2" },
              { text:"Website Erstellung", href:"#seo-teil-2", key:"3" },
              { text:"A/B-Tests & Optimierung", href:"#a-b-testing", key:"4" },
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column flex-md-row justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Kontakt aufnehmen"
            href="#contact-form"
          />
          <Btn
            type="secondary"
            text="Zu den Erfolgsprojekten"
            href="#erfolgsprojekte" 
          />
        </div>
      </div>
    </Section>
    <HeroBg 
      bgImage={bgImage}
    />

    <Section>
      <div className="row" id="von-der-idee-zur-website">
        <div className="col">
          <div className="headings">
            <h2>Von der Idee zur Website. Und noch viel weiter.</h2>
            <p className="subheading">Der Prozess der Website Erstellung besteht nicht nur aus technischen und kreativen Prozessen, sondern benötigt viel Vorarbeit, um die passende Keywordstruktur zu konzipieren. Welche Seiten werden benötigt? Wie hängen sie zusammen? Und auf welcher Seite steht was?</p>
          </div>
        </div>
      </div>
      <div className="row" id="seo-teil-1">
        <div className="col-md-10">
          <ContentRectangle 
            number={1}
            heading="SEO Teil 1: Struktur & Keyword Recherche"
            text={<>
            Damit Interessenten die eigene Website, Produkte und Dienstleistungen in <strong>Suchmaschinen</strong> finden können, müssen einige Vorarbeiten geleistet werden.
            Welche <strong>Keywords und Suchanfragen</strong> sind relevant? Auf welche Unterseite sollen diese verlinken? Welche <strong>Verlinkungen</strong> innerhalb deiner Seite sind wichtig?
            Das sind nur einige der Fragen, die man sich bei der Konzeption einer neuen Website stellen muss.<br />
            Das Ziel: eine <strong>strukturierte Website</strong>, auf welcher jede Suchanfrage eine passende <strong>Zielseite </strong> zugeordnet werden kann, damit Nutzer schnellstmöglich an die gesuchten Informationen kommen. 
            </>}
          />
        </div>
      </div>
      <div className="row" id="webdesign">
        <div className="col-md-9 offset-md-3">
          <ContentRectangle
            number={2}
            heading="Nach der Struktur kommt das Webdesign"
            text={<>
            Nachdem der Aufbau der Website und die relevantesten Seiten feststehen, wird die <strong>Website gestaltet</strong>. Gutes Webdesign legt den Fokus dabei
            nicht nur auf einen schönen visuellen Auftritt der Marke, sondern auch auf eine <strong>zielgerichtete Nutzerführung</strong>. <br />Durch die richtige Anordnung von 
            Elementen, kann man den <strong>Informationsfluss steuern</strong> und Websitenbesucher zur gewünschten Zielaktion (z.B. Kontaktaufnahme oder Kauf) leiten.
            Klickbare Mockups ermöglichen dabei die neue Website bereits vor ersten Programmierarbeiten erlebbar zu machen.<br />
            Wichtig bei der Gestaltung ist die <strong>Berücksichtigung</strong> aller Viewports, also <strong>jeder Displaygröße</strong> - vom Smartphone bis hin zum großen PC-Bildschirm.
            </>}
          />
        </div>
      </div>
      <div className="row" id="seo-teil-2">
        <div className="col-md-8">
          <ContentRectangle
            number={3}
            heading="SEO Teil 2: Jetzt kommt der technische Part"
            text={<>
            Nun erwachen die Struktur und das Webdesign zum Leben. Dabei werden weitere, <strong>technische SEO-relevante Faktoren</strong> berücksichtig, damit nicht nur der Besucher die Website
            nutzen kann, sondern auch  Suchmaschinen. Ein <strong>Content-Management-System (CMS)</strong> wird angebunden und andere technische Anforderungen an das Projekte werden umgesetzt.
            </>}
          />
        </div>
      </div>
      <div className="row" id="a-b-testing">
        <div className="col-md-9 offset-md-2">
          <ContentRectangle
            number={4}
            heading="A/B-Testing: Mit dem Going Live ist es nicht getan"
            text={<>
            Auch die beste Konzeption und Design verbergen <strong>unbekannte Potenziale</strong>. Um diese zu entdecken, können per A/B-Tests <strong>verschiedene Varianten</strong> des Seitendesigns gegeneinander 
             <strong> vertestet</strong> werden. Klicken mehr Nutzer bei einem grünen oder blauen Button? Führt ein weiteres Gütesiegel zu mehr Verkäufen? 
            Finde in einzelnen Tests Antworten auf diese Fragen und <strong>optimiere deine Website</strong> forlaufend.
            </>}
          />
        </div>
      </div>
    </Section>

    <Section>
      <div className="row" id="erfolgsprojekte">
        <div className="col">
          <div className="headings">
            <h2>Erfolgsprojekte</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <ContentBlock 
                heading="Wahl Photography"
                text={<>
                Dank dem Erfolg ihrer Website wurde aus einem ursprünglichen Hobby eine Selbstständigkeit.
                Das Geheimnis sind <strong>emotionale Bilderreportagen</strong>, die die <strong>Qualität</strong> der Dienstleistung 
                <strong> auf jedem Endgerät</strong> spürbar machen.
                </>}
                btnText="wahl-photography.com"  
                linkTo="https://www.wahl-photography.com/"
                linkToExternal={true}
                headingBgStart={10}
                headingBgWidth={50}
              />
            </div> 
            <div className="col-md-6 offset-md-1 order-first order-md-last ">
              <a href="https://www.wahl-photography.com/" target="_blank" rel="noreferrer" className="project-link">
                <StaticImage 
                  src="../images/projects/project-anne-catrin-wahl-fotografie.png" 
                  alt="Wahl Photography Website" 
                  formats={["auto","webp"]}
                  placeholder="blurred"
                  layout="constrained"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 ">
              <a href="https://gartenfreunde-offenburg.de/" target="_blank" rel="noreferrer" className="project-link">
                <StaticImage 
                  src="../images/projects/project-gartenfreunde-offenburg.png" 
                  alt="Gartenfreunde Offenburg e.V. Website" 
                  formats={["auto","webp"]}
                  placeholder="blurred"
                  layout="constrained"
                />
              </a>
            </div>
            <div className="col-md-4 offset-md-1">
              <ContentBlock 
                heading="Gartenfreunde Offenburg e.V."
                text={<>
                Gerade in den jüngeren Generationen ist Hobby-Gärtnern ein großer Trend. Diesen Wandel versuchte die Gartenfreunde Offenburg e.V. mitzugehen.
                Ein <strong>moderner Webauftritt und einfache Kontaktaufnahme</strong> sind der Erfolg einer nun vollen Warteliste.
                </>}
                btnText="gartenfreunde-offenburg.de" 
                linkTo="https://gartenfreunde-offenburg.de/"
                linkToExternal={true}
                headingBgStart={30}
                headingBgWidth={70}
              />
            </div>
          </div>
        </div>
      </div>
      
    </Section>
    
    <ContactFormSection 
      heading="Erzähle uns von deinen Ideen"
      text="Schicke uns eine Nachricht mit deinem Anliegen oder deinen Fragen."
    />
    
  </Layout>
  </>
)
export default WebsiteAndSeoPage
